import Thunk from 'redux-thunk';
import { applyMiddleware, legacy_createStore, compose } from 'redux';

import reducers from './rootReducer';
import { apiMiddleware } from '../middlewares/index';

const store: ReturnType<typeof legacy_createStore> = legacy_createStore(
  reducers,
  compose(applyMiddleware(Thunk), applyMiddleware(apiMiddleware))
);

export default store;
