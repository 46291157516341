const ROLES = {
  superAdmin: 1,
  manager: 2,
  operator: 3,
  tpBankSuperAdmin: 4,
  tpBankManager: 5,
  tpBankOperator: 6,
  tpBank: 7,
};

const ACTION_INFO = {
  CREATE: 1,
  EDIT: 2,
  DELETE: 3,
  blocked: 4,
  unblocked: 5,
  APPROVED_BY: 6,
  REJECTED: 7,
};

const USER_STATUS = {
  ACTIVE: 1,
  EMAIL_VERIFICATION_PENDING: 2,
  APPROVAL_PENDING: 3,
  INACTIVE: 4,
  DELETED: 5,
  REJECTED: 6,
};

const MESSAGE_TYPE = {
  FIRST_PRESENTMENT: 4, // when first Manual case will create then it will be a first presentment.
  RETRIEVAL_REQUEST: 1,
  ACCEPT_RETRIEVAL_REQUEST: 10, // TP bank accepts and case will be closed.
  FULFILLMENT: 12,
  ACCEPT_FULFILLMENT: 7, // AESA will accept and case will be closed.
  FIRST_CHARGE_BACK: 2,
  ACCEPT_FIRST_CHARGE_BACK: 11, // TP bank accepts and case will be closed.
  SECOND_PRESENTMENT: 13,
  ACCEPT_SECOND_PRESENTMENT: 8, // AESA will accept and case will be closed.
  FINAL_CHARGE_BACK: 3, // ASEA will launch final charge back and case will be closed.
  NOT_FULFILLMENT: 5, // AESA will repeat the process.
  NOT_SECOND_PRESENTMENT: 6, // AESA can only perform final charge back.
  CLOSE: 9,
};

const TRANSACTION_TYPE = {
  POS: 1,
  ATM: 2,
};

const CASE_TYPE = {
  API: 1,
  MANUAL: 2,
};

const NOTIFICATION_CHANNEL = {
  EMAIL: 1,
  DESKTOP: 2,
  EMAIL_DESKTOP: 3,
};

const NOTIFICATION_TYPE = {
  CUSTOM_NOTIFICATION: 1,
  TEMPLATE_NOTIFICATION: 2,
  CASE_NOTIFICATION: 3,
  SCHEDULE_NOTIFICATION: 4,
};

const AUDIENCE_TYPE = {
  SELECT_USER_FROM_AESA_PANEL: 1,
  SELECT_USER_FROM_TP_BANK_PANEL: 2,
  GROUP_ALL_AESA_MANAGER: 3,
  GROUP_ALL_AESA_OPERATOR: 4,
  GROUP_ALL_TP_BANK_SUPER_ADMIN: 5,
  GROUP_ALL_TP_BANK_OPERATOR: 6,
  GROUP_ALL_TP_BANK_MANAGER: 7,
  USER_GROUP: 8,
};

const NOTIFICATION_STATUS = {
  ACTIVE: 1,
  PENDING: 2,
  DRAFT: 3,
};

const MAX_COUNT_LIMIT = 5;
const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE_LIMIT || '5000000';

const MAX_DATE_RANGE = process.env.REACT_APP_MAX_DATE_RANGE || 6;

const SCAN_FLAG = process.env.REACT_APP_SCAN_FLAG || 'false';

const SUPPORTED_FILE_TYPES_FOR_CASE_DOC = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'text/plain',
  'image/gif',
  'image/tiff',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
];

const ACCOUNT_INFO = {
  ROLES,
  ACTION_INFO,
  USER_STATUS,
  MESSAGE_TYPE,
  TRANSACTION_TYPE,
  CASE_TYPE,
  NOTIFICATION_CHANNEL,
  NOTIFICATION_TYPE,
  NOTIFICATION_STATUS,
  MAX_COUNT_LIMIT,
  MAX_FILE_SIZE,
  MAX_DATE_RANGE,
  AUDIENCE_TYPE,
  SCAN_FLAG,
  SUPPORTED_FILE_TYPES_FOR_CASE_DOC,
};

export default ACCOUNT_INFO;
